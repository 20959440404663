<template>
  <div>
    <!--上传图片搜索预览-->
    <div class="preview_image_search" v-if="base64Url"
      :style="{'opacity': scrollTop < 15 ? 1: 0}">
      <img :src="base64Url" alt="">
    </div>
    <!--活动广告位-->
    <div class="list_banner_style" ref="list_banner_style" v-if="listBannerImg && !fromListing">
      <img :src="listBannerImg" alt="">
    </div>
    <!--主体内容-->
    <div class="publicList_box">
      <!--侧边栏分类-->
      <div class="fixed_box_style" v-if="navBarFixed && shopList.length>0"></div>
      <div class="classification_box" :style="handleFixedStyles()"
        :class="navBarFixed && shopList.length>0 ? 'fixed_classification_box' : ''">
        <!--当前分类项-->
        <div class="classify_box" v-if="classifyList.length>0">
          <Tree class="tree_box" :data="classifyList" :render="renderContent" @on-select-change="selectCategory"></Tree>
        </div>
        <!--图片类型-->
        <div class="picture_type_box">
          <h2 class="title">{{ $t('key1005362') }}</h2>
          <CheckboxGroup v-model="pageParams.imageTypeList" @on-change="getList()">
            <p class="img_type_style" v-for="(item, index) in imgType" :key="index">
              <Checkbox :label="item.value">{{ item.label }}</Checkbox>
            </p>
          </CheckboxGroup>
        </div>
      </div>
      <!--右边列表区域-->
      <div class="shop_list_box">
        <!--面包屑-->
        <div class="breadcrumb_box">
          <span class="breadcrumb_title">{{ $t('key1005331') }}</span>
          <Breadcrumb separator=">">
            <BreadcrumbItem v-for="(item,index) in breadcrumbList" :key="index"
              :to="handleBreadcrumb(item)">{{ item.title }}</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <!--商品筛选条件区域-->
        <div class="filter_box">
          <!--排序-->
          <div class="filter_box_item">
            <span>{{ $t('key1002176') }}</span>
            <div class="sort_content">
              <div class="sort_content_item" v-for="(ele, idx) in sortList" :key="idx" @click="sortBtn(ele, idx)">
                <span class="sort_title" :class="ele.selected ? 'sort_active' : ''">{{ ele.name }}</span>
                <span class="sort_icon_box" v-if="ele.sort">
                  <Icon class="dropup_icon" size="16" type="md-arrow-dropup"
                    :color="ele.type === 'up' ? '#FD5425' : '#515A6E'" @click.stop="sortupDownBtn(idx,'up')"/>
                  <Icon class="dropdown_icon" size="16" type="md-arrow-dropdown"
                    :color="ele.type === 'down' ? '#FD5425' : '#515A6E'" @click.stop="sortupDownBtn(idx,'down')"/>
                </span>
                <span class="line_txt"></span>
              </div>
            </div>
          </div>
          <!--库存-->
          <div class="filter_box_item stock_styles">
            <Select class="stock_select" v-model="pageParams.inventoryType">
              <Option v-for="item in stockTypeList" :value="item.value" :key="item.value">{{ item.name }}</Option>
            </Select>
            <div class="stock_input_box">
              <InputNumber class="stock_input" :placeholder="$t('key1004398')" :max="9999999999" :min="0"
                @on-focus="handleFocus(pageParams.minInventory)" @on-blur="handleBlur(pageParams.minInventory)"
                v-model="pageParams.minInventory" :formatter="(value) => parseInt(value)"></InputNumber>
              <span class="line_item">-</span>
              <InputNumber class="stock_input" :placeholder="$t('key1004399')" :max="9999999999" :min="0"
                @on-focus="handleFocus(pageParams.maxInventory)" @on-blur="handleBlur(pageParams.maxInventory)"
                v-model="pageParams.maxInventory" :formatter="(value) => parseInt(value)"></InputNumber>
            </div>
          </div>
          <!--价格-->
          <div class="filter_box_item" v-if="userInfo">
            <span>{{ $t('key1004402') }}</span>
            <InputNumber class="price_input" :placeholder="$t('key1004400')" :max="9999999999" :min="0"
              @on-focus="handleFocus(pageParams.minPrice)" @on-blur="handleBlur(pageParams.minPrice)"
              v-model="pageParams.minPrice" :formatter="(value) => formatterNumber(value, 2)"></InputNumber>
            <span class="line_item">-</span>
            <InputNumber class="price_input" :placeholder="$t('key1004401')" :max="9999999999" :min="0"
              @on-focus="handleFocus(pageParams.maxPrice)" @on-blur="handleBlur(pageParams.maxPrice)"
              v-model="pageParams.maxPrice" :formatter="(value) => formatterNumber(value, 2)"></InputNumber>
          </div>
          <!--所属仓库-->
          <div class="filter_box_item" v-if="userInfo">
            <span>{{ $t('key1001642') }}</span>
            <vueTreeSelect
              style="width: 210px"
              :clearable="true"
              :searchable="true"
              :multiple="true"
              :noResultsText="$t('key1000087')"
              :noOptionsText="$t('key1000841')"
              :placeholder="$t('key1000086')"
              :normalizer="setNormalizer"
              :options="warehouseData"
              v-model="ymsWarehouseIdList"
              :defaultExpandLevel="1"
              @input="(data) => changeYmsWarehouse(data)"
              class="distributor_reset_vueTreeselect_style"
            />
          </div>
          <!--一键刊登支持平台-->
          <div class="filter_box_item" v-if="userInfo">
            <span>{{ $t('key1005363') }}</span>
            <Select v-model="pageParams.platformIdList" style="width:150px" @on-change="getList()">
              <Option v-for="(item,index) in platformList" :key="index"
                :value="item.platformId">{{ item.name }}</Option>
            </Select>
          </div>

          <!--收藏-->
          <div class="filter_box_item">
            <span>{{ $t('key1005364') }}</span>
            <Select v-model="pageParams.isCollect" style="width:120px" @on-change="getList()">
              <Option :value="2">{{ $t('key1000095') }}</Option>
              <Option :value="0">{{ $t('key1000783') }}</Option>
              <Option :value="1">{{ $t('key1000782') }}</Option>
            </Select>
          </div>
          <Button type="primary" style="margin: 0 30px 16px 0;" v-if="handleShowDefine() && showDefine"
            @click="queryBtn">{{ $t('key1000096') }}</Button>
        </div>
        <!--列表区域-->
        <div class="shop_content_box">
          <div class="shop_content_item" v-for="(item, index) in shopList" :key="index">
            <div class="img_style_box">
              <!--轮播图-->
              <Carousel dots="none" @on-click="(value)=> changeCarousel(value, item)">
                <CarouselItem v-for="(ele,idx) in item.productImageList" :key="idx">
                  <div class="img_style_box">
                    <imageBox :src="getThumbnailImage(ele.path)" :animation="true"></imageBox>
                  </div>
                </CarouselItem>
              </Carousel>
              <!--操作按钮-->
              <div class="options_box">
                <span class="opts" :style="{
                  width: ((getPermission('ymsDistributorProductInfo_download') && userInfo) || !userInfo) && !fromListing ? '112px' : '100%'
                }" v-if="(getPermission('ymsDistributorProductInfo_publish') && userInfo) || !userInfo || fromListing"
                  @click="publishBtn(item)">{{ $t('key1004403') }}</span>
                <span class="line_item"></span>
                <span class="opts"
                  v-if="((getPermission('ymsDistributorProductInfo_download') && userInfo) || !userInfo) && !fromListing"
                  @click="downProductData('single', item.ymsProductId)">{{ $t('key1004404') }}</span>
              </div>
            </div>
            <h2 class="list_title" :title="item.name" @click="toShopDetails(item)">{{ item.name }}</h2>
            <div class="money_box" @click="toShopDetails(item)">
              <div class="price" v-if="userInfo">
                <!--有活动价-->
                <template v-if="item.activityPriceTalg">
                  <template v-if="item.showOriginalPriceTalg">
                    <span>{{ currencyState + currencySymbol(currencyState) + item.count_activityPrice }}</span>
                    <span class="original_price_style">{{ currencyState + currencySymbol(currencyState) + item.count_minSuggestPrice }}</span>
                  </template>
                  <template v-if="item.showActivityPriceTalg">
                    <span>{{ currencyState + currencySymbol(currencyState) + item.count_activityPrice }}</span>
                  </template>
                  <template v-if="item.showMinSuggestPriceTalg">
                    <span>{{ currencyState + currencySymbol(currencyState) + item.count_minSuggestPrice }}</span>
                  </template>
                </template>
                <!--无活动价-->
                <template v-else>
                  <span>{{ currencyState + currencySymbol(currencyState) + item.count_minSuggestPrice }}</span>
                </template>
              </div>
              <span class="price" v-else>{{ $t('key1005338') }}</span>
            </div>
            <!--收藏图标-->
            <div class="subscribe_box" v-if="(item.subscribed && (getPermission('ymsDistributorMerchantSubscription_cancelSubscription') || fromListing)
                && userInfo) || (item.subscribed && !userInfo)">
              <Icon size="20" color="#FF5310" type="md-heart" @click="unsubscribeBtn('single', item,index)"/>
            </div>
            <!--未收藏图标-->
            <div class="unsubscribed_box" v-if="(!item.subscribed && (getPermission('ymsDistributorMerchantSubscription_subscription') || fromListing)
            && userInfo) || (!item.subscribed && !userInfo)">
              <Icon size="20" type="md-heart-outline" @click="subscribeBtn('single', item, index)"/>
            </div>
            <!--活动标签-->
            <div class="activity_label_box" v-if="item.activityGoodsLabelList">
              <template v-for="ele in item.activityGoodsLabelList">
                <span class="activity_label_item" v-if="ele" style="background-color: #FF1810;">{{ ele }}</span>
              </template>
            </div>
          </div>
          <img v-if="isGet" style="display: block;margin: 10px auto 0 auto;color: #FD5425" :src="loadingSvg"/>
        </div>
      </div>
    </div>
    <!--刊登平台弹窗-->
    <publishingPlatformModal ref="publishingPlatform" @changePlatform="changePlatform"></publishingPlatformModal>
    <!--刊登店铺弹窗-->
    <selectPublishStoreModal ref="selectPublishStore"></selectPublishStoreModal>
  </div>
</template>

<script>
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import imageBox from '@/components/common/imageBox';
import {getDistributorErpConfig} from "@/utils/user";
import api from '@/api/';
import vueTreeSelect from '@riophae/vue-treeselect';
import selectPublishStoreModal from "@/components/common/selectPublishStoreModal.vue";
import publishingPlatformModal from "@/components/common/publishingPlatformModal.vue";
import {formatterNumber, pageJump, amountHandel, getDomainSiteInfo, commonSessionStorage} from "@/utils/common";

export default {
  name: "publicList",
  mixins: [Mixin],
  props: {
    supplierMerchantId: {
      type: String,
      default: null
    }
  },
  data() {
    let v = this;
    return {
      shopType: 'shop', //  shop 商品列表（默认）
      pageParamsStatus: false,
      loadingSvg: require('@/assets/images/svg/loading-balls.svg'),
      breadcrumbList: [
        {path: '/index', title: alias1bddc4d174174d47a6dfb0437dace856.t('key1005193'), talg: true}
      ],
      shopList: [],
      hasNextPage: true,
      pageParams: {
        distributorMerchantId: null, // 分销商商户号
        imageTypeList: [], // 图片类型 0:自拍模特图, 1:网红图, 2:实物摆拍图
        isInStock: 0, // 是否有货(有:1)
        maxPrice: null, // 金额上限
        minPrice: null, // 金额下限
        minInventory: null,  // 库存上限
        maxInventory: null, // 库存下限
        createdStartTime: null,
        createdEndTime: null,
        orderBy: 'overallRating', // 排序方式(可选值 综合:overallRating 总销量:totalSoldQuantity 金额:price 上新：shelvedTime 默认:overallRating )
        searchValue: '', // 全文检索关键词
        supplierMerchantId: null, // 供应商商户号
        type: null, // 类型(0 每日上新 1潮流女装 2时尚男装 3精品童装 4热销产品 5好货推荐)
        upDown: null, // down  降序  up 升序
        ymsActivityPlanId: null, // 活动流水号
        ymsProductCategoryIdList: [], // 分类流水号列表
        inventoryType: 0, // 库存类型(0 总库存 1供应商库存 2中心仓库存)
        isCollect: 2, // 是否收藏(有:1 无：0 全部：2)
        platformIdList: null, // 刊登支持平台
        ymsWarehouseIdList: [],
        productImages: [], // 上传图片搜索集合
        pageNum: 1,
        pageSize: 20
      },
      isGet: false, // 是否在获取数据
      imgType: [
        {label: alias1bddc4d174174d47a6dfb0437dace856.t('key1000634'), value: '0'},
        {label: alias1bddc4d174174d47a6dfb0437dace856.t('key1000635'), value: '1'},
        {label: alias1bddc4d174174d47a6dfb0437dace856.t('key1000636'), value: '2'}
      ],
      sortList: [
        {name: alias1bddc4d174174d47a6dfb0437dace856.t('key1004405'), paramsKey: 'overallRating', sort: false, selected: true},
        {name: alias1bddc4d174174d47a6dfb0437dace856.t('key1004406'), paramsKey: 'totalSoldQuantity', type: null, sort: true, selected: false},
        {name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000757'), paramsKey: 'price', sort: true, type: null, selected: false},
        {name: alias1bddc4d174174d47a6dfb0437dace856.t('key1004407'), paramsKey: 'shelvedTime', type: null, sort: true, selected: false},
      ],
      classifyList: [],
      navBarFixed: null,
      fixedClassificationStyle: {},
      currentIndex: 0,
      showDefine: true,
      screenWidth: document.body.clientWidth,
      stockTypeList: [
        {value: 0, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1004408')},
        {value: 1, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000789')},
        {value: 2, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000778')},
      ],
      platformList: [],
      ymsWarehouseIdList: [],
      warehouselist: [],
      warehouseData: [],
      setNormalizer(node) {
        return {
          id: node.ymsWarehouseId,
          label: node.name,
          children: node.children
        };
      },
      scrollTop: 0,
      base64Url: null,
      ymsProductId: null,
      originalPageParams: {}
    };
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
    fromListing() {
      return this.$store.state.fromListing
    },
    listBannerImg() {
      let ongoingActivitiesData = this.$store.state.ongoingActivitiesData;
      let ymsActivityPlanId = this.$route.query.ymsActivityPlanId;
      let imagePath = '';
      if (ymsActivityPlanId) {
        if (ongoingActivitiesData.length > 0) {
          ongoingActivitiesData.map((item) => {
            if (item.ymsActivityPlanId === ymsActivityPlanId) {
              imagePath = item.imagePath;
            }
          });
        }
      }
      return imagePath;
    },
  },
  mounted() {
    let v = this;
    window.addEventListener('scroll', v.rollingLoadData);
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        v.screenWidth = window.screenWidth;
      })();
    };
  },
  methods: {
    formatterNumber,
    // 滚动加载更多数据
    rollingLoadData() {
      let v = this;
      const imgH = 374 * 5;
      let hostname = window.location.hostname;
      let arr = hostname.split('.yms.com');
      if (arr[0].includes('shop') || v.$route.path === '/shopList') {
        v.handleScroll();
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        let scrollHeight = document.body.scrollHeight;
        let clientHeight = document.body.clientHeight;
        if (scrollHeight - scrollTop - clientHeight < imgH) {
          if (v.hasNextPage) {
            v.getListData();
          }
        }
      }
    },
    // 处理滚动吸顶
    handleScroll() {
      let v = this;
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      let scrollHeight = document.body.scrollHeight;
      let clientHeight = document.body.clientHeight;
      this.scrollTop = scrollTop;
      let talgHeight = v.$refs['list_banner_style'] ? v.$refs['list_banner_style'].offsetHeight + 15 : 15;
      if (scrollTop + clientHeight >= scrollHeight - 395) { // 395
        v.navBarFixed = false;
      } else {
        if (scrollTop > talgHeight) {
          v.navBarFixed = true;
        } else {
          v.navBarFixed = false;
        }
        v.$store.commit('navBarFixed', v.navBarFixed);
      }
    },
    // 获取已经映射商品的平台数据
    getProductPlatformData() {
      let v = this;
      v.axios.get(api.get_ymsPlatform_api_queryProductPlatform).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas || [];
          if (data && data.length > 0) {
            v.platformList = data;
            v.platformList.unshift({
              platformId: 'all',
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000576')
            })
          }
        }
      });
    },
    // 初始化参数
    Initialization(data) {
      let v = this;
      v.shopType = data.lang ? data.lang : 'shop';
      v.handleFixedStyles();
      this.originalPageParams = JSON.parse(JSON.stringify(this.pageParams));
      v.reset();
      if (v.shopType !== 'shop') {
        getDomainSiteInfo().then((list) => {
          if (list && list.length > 0) {
            let ymsSiteId = list[0].ymsSiteId;
            v.getCloudTreeData(api.post_ymsProductCategory_shopping_queryForExistsProduct, null, null, ymsSiteId).then((treeData) => {
              v.handleClassifieData(data, false, treeData);
            });
          }
        })
      } else {
        v.handleClassifieData(data, false);
      }
      if (v.userInfo) {
        v.getProductPlatformData();
        // 商户所属仓库的基础数据
        v.getDistributorWarehouseData(v.userInfo.merchantId).then((data) => {
          if (data && data.length > 0) {
            v.warehouselist = data;
            let list = [{
              name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000095'),
              ymsWarehouseId: 'null',
              children: []
            }];
            list[0].children = data;
            v.warehouseData = list;
          }
        });
      }
      v.$store.commit('showHover', true);
      // 店铺查询
      let supplierMerchantId = null;
      let distributorInfo = localStorage.getItem('distributorInfo');
      if (distributorInfo) {
        let obj = JSON.parse(distributorInfo);
        supplierMerchantId = obj.ymsMerchantId;
      }
      let hostname = window.location.hostname;
      let arr = hostname.split('.yms.com');
      if (arr[0].includes('shop')) {
        v.pageParams.supplierMerchantId = supplierMerchantId || v.supplierMerchantId;
        v.pageParams.isFavoriteSuppliers = 1;
      }
      if (Object.keys(data).length > 0) {
        if (data.shopType !== 'shop') {
          v.pageParams.supplierMerchantId = supplierMerchantId || v.supplierMerchantId;
        }
        // tab切换根据商品类型查询(全部商品、新品不需要传商品活动id)
        if (['all', 'news'].includes(data.activityType)) {
          v.pageParams.ymsActivityPlanId = null;
        }
        // 其他活动传活动id
        else if (data.ymsActivityPlanId) {
          this.pageParams.ymsActivityPlanId = data.ymsActivityPlanId;
        }
        // 新品
        if (data.activityType === 'news') {
          v.pageParams.createdStartTime = v.$uDate.getUniversalTime(new Date().setDate((new Date().getDate() - 7)), 'fulltime');
          v.pageParams.createdEndTime = v.$uDate.getUniversalTime(new Date(), 'fulltime');
        }
        // 头部搜索框--查所有商品
        if (data.searchValue) {
          v.pageParams.searchValue = data.searchValue;
          v.pageParams.ymsActivityPlanId = null;
        } else {
          v.pageParams.searchValue = '';
        }
        // 点击分类进入商品列表
        if (data.ymsProductCategoryId) {
          v.pageParams.ymsProductCategoryIdList = [data.ymsProductCategoryId];
        }
        // 点击推荐商品的更多进入商品列表
        if (data.commodityType) {
          v.pageParams.type = data.commodityType;
        }
        // 上传图片搜索
        if (data.imgUrl && data.base64Url) {
          v.base64Url = data.base64Url;
          v.pageParams.productImages = [data.imgUrl];
        }
      }
      v.getList();
    },
    // 处理侧边栏分类的数据
    handleClassifieData(data, value, treeData) {
      let v = this;
      v.classifyList = [];
      v.breadcrumbList = [{path: '/index', title: alias1bddc4d174174d47a6dfb0437dace856.t('key1005193'), talg: true}];
      let ymsClassifyList = treeData || JSON.parse(localStorage.getItem('classificationData')) || v.$store.state.classificationData;
      let parentCodeList = ['01', '02']; // 一级分类的
      if (ymsClassifyList.length > 0) {
        setTimeout(() => {
          if (data.activityType || data.searchValue) {
            let title = '';
            if (data.activityType) {
              let ongoingActivitiesData = commonSessionStorage.getItem('ongoingActivitiesData') || [];
              if (ongoingActivitiesData.length > 0) {
                ongoingActivitiesData.map((item) => {
                  if (item.activityType === data.activityType) {
                    title = item.activityTitle;
                  }
                })
              }
            } else if (data.searchValue) {
              title = data.searchValue
            } else {
              title = '';
            }
            v.breadcrumbList.push({
              path: '',
              title: title,
              talg: true
            });
          }
        }, 3000);
        // 从频道进入或者推荐商品类型为（每日上新、HOT 热销商品、好货推荐）店铺列表、全局搜索关键词--默认展示所有一级分类
        if (data.activityType || (['4', '5', '6'].includes(data.commodityType)) || (v.shopType !== 'shop' && !value) || data.searchValue) {
          let newList = ymsClassifyList.filter((item) => {
            if (parentCodeList.includes(item.parentCode)) {
              item.children = [];
              return item;
            }
          });
          if (newList.length > 0) {
            newList.map((item) => {
              item['titleTalg'] = true;
            });
            v.classifyList = newList;
          }
        }
        // 点击分类进入商品列表或者(潮流女装、时尚男装、精品童装)根据分类id获取分类层级关系
        if ((v.shopType === 'shop' && data.ymsProductCategoryId) || (v.shopType !== 'shop' && data.ymsProductCategoryId && value)) {
          let ymsProductCategoryIds = [];

          function treeToList(tree, list = []) {
            tree.forEach(node => {
              list.push(node)
              node.children && treeToList(node.children, list)
            })
            return list
          }

          let originalData = treeToList(ymsClassifyList);
          // 获取当前分类的数据
          let newList = originalData.filter((item) => {
            if (item.ymsProductCategoryId === data.ymsProductCategoryId) {
              return item;
            }
          });
          if (newList.length > 0) {

            function handleData(list) {
              originalData.map((ele) => {
                list.map((item) => {
                  if (item.parentCode === ele.productCategoryCode) {
                    ymsProductCategoryIds.unshift(ele.ymsProductCategoryId);
                    handleData([ele])
                  }
                })
              })
            }

            handleData(newList)
            ymsProductCategoryIds = [...ymsProductCategoryIds, data.ymsProductCategoryId];
            ymsProductCategoryIds.forEach(i => {
              originalData.forEach(j => {
                if (i === j.ymsProductCategoryId) {
                  j.title = j.name;
                  v.breadcrumbList.push(j);
                }
              });
            });
            originalData.map((item) => {
              if (ymsProductCategoryIds.includes(item.ymsProductCategoryId)) {
                item.expand = true;
                if (item.ymsProductCategoryId === data.ymsProductCategoryId) {
                  item.selected = true;
                }
              } else {
                item.children = [];
              }
              if (parentCodeList.includes(item.parentCode)) {
                item['titleTalg'] = true;
              }
            });
            v.classifyList = ymsClassifyList;
          }
        }
        v.$forceUpdate();
      }
    },
    // 侧边栏分类--点选中分类筛选
    selectCategory(data, item) {
      let v = this;
      v.handleClassifieData(item, true);
      v.pageParams.ymsProductCategoryIdList = [item.ymsProductCategoryId];
      window.scrollTo(0, 0);
      setTimeout(() => {
        v.pageParamsStatus = true;
      }, 300);
      // v.$router.push({
      //   path: '/shopList',
      //   query: {
      //     ymsProductCategoryId: item.ymsProductCategoryId,
      //     from: v.fromListing ? 'listing' : undefined
      //   }
      // });
    },
    // 处理面包屑的路径
    handleBreadcrumb(data) {
      let path = '';
      if (this.fromListing) return;
      if (!data.talg) {
        path = `/shopList?ymsProductCategoryId=${data.ymsProductCategoryId}`
      } else {
        path = data.path;
      }
      return path;
    },
    // 处理分类展示
    renderContent(h, {root, node, data}) {
      let talg = data.titleTalg ? 'catalogue_title' : '';
      return h('div', {
        attrs: {
          title: data.productCategoryNavigation
        },
        class: `title_styles ${talg}`
      }, data.name)
    },
    // 重置参数
    reset() {
      this.pageParams = JSON.parse(JSON.stringify(this.originalPageParams));
      this.sortList.map((item) => {
        item.selected = false;
        if (item.paramsKey === 'overallRating') {
          item.selected = true;
        } else {
          item.type = null;
        }
      });
      this.$forceUpdate();
    },
    // 价格的确定按钮
    queryBtn() {
      this.handleFilterData('minPrice', 'maxPrice');
      this.handleFilterData('minInventory', 'maxInventory');
      this.showDefine = false;
    },
    // 处理价格、库存的筛选数据
    handleFilterData(minData, maxData) {
      let v = this;
      let min = Number(v.pageParams[minData]);
      let max = Number(v.pageParams[maxData]);
      if (min || max) {
        if (min < max) {
          setTimeout(() => {
            v.pageParams[minData] = min;
            v.pageParams[maxData] = max;
            v.pageParamsStatus = true;
          }, 200)
        } else {
          setTimeout(() => {
            v.pageParams[minData] = max;
            v.pageParams[maxData] = min;
            v.pageParamsStatus = true;
          }, 200)
        }
        v.$forceUpdate();
      }
    },
    // 处理获取焦点
    handleFocus(data) {
      if (data) {
        this.showDefine = true;
      }
    },
    // 处理获取失去焦点
    handleBlur(data) {
      if (data) {
        this.showDefine = true;
      }
    },
    handleShowDefine() {
      let v = this;
      if (v.pageParams.minPrice || v.pageParams.maxPrice || v.pageParams.minInventory || v.pageParams.maxInventory) {
        return true;
      } else {
        return false;
      }
    },
    getList() {
      this.shopList = [];
      this.pageParams.pageNum = 1;
      this.getListData();
    },
    // 获取商品列表的数据
    getListData() {
      let v = this;
      let obj = {};
      if (v.isGet) return;
      if (v.userInfo.merchantId) {
        obj.distributorMerchantId = v.userInfo.merchantId;
      }
      v.isGet = true;
      v.hasNextPage = false;
      let params = Object.assign({}, v.pageParams, obj);
      if (v.pageParams.platformIdList) {
        let newList = [];
        if (v.pageParams.platformIdList == 'all') {
          v.platformList.map((item) => {
            newList.push(item.platformId)
          });
        } else {
          newList = [v.pageParams.platformIdList]
        }
        params.platformIdList = newList;
      }
      if (v.pageParams.ymsWarehouseIdList == 'null') {
        params.ymsWarehouseIdList = [];
      } else {
        params.ymsWarehouseIdList = v.pageParams.ymsWarehouseIdList;
      }
      let config = {
        headers: {
          'Language': v.languagesVal
        }
      }
      v.axios.post(api.post_ymsProductInfo_shopping_query + '?fresh=KEEPNEW', Object.assign({}, params, obj), config).then(response => {
        v.isGet = false;
        if (response.data.code === 0) {
          let data = response.data.datas;
          let list = data.list || [];
          v.shopList.push(...list);
          v.pageParams.pageNum = data.nextPage;
          v.hasNextPage = data.hasNextPage;
        }
        if (v.shopList.length > 0) {
          v.shopList.map((item) => {
            item.activityGoodsLabelList = [];
            item.activityPriceTalg = false;
            item.showOriginalPriceTalg = false;
            item.showActivityPriceTalg = false;
            item.showMinSuggestPriceTalg = false;
            if (item.activityPrice) {
              item.activityPriceTalg = true;
              item.showOriginalPriceTalg = item.minSuggestPrice > item.activityPrice;
              item.showActivityPriceTalg = item.minSuggestPrice < item.activityPrice;
              item.showMinSuggestPriceTalg = item.minSuggestPrice === item.activityPrice;
            }
            item.orgin_minSuggestPrice = item.minSuggestPrice;
            item.count_activityPrice = amountHandel(item.activityPrice);
            item.count_minSuggestPrice = amountHandel(item.minSuggestPrice);
            let activityGoodsLabels = item.activityGoodsLabels || [];
            if (activityGoodsLabels.length > 0) {
              item.activityGoodsLabelList = activityGoodsLabels.map((ele) => {
                return v.handleMultilingualFields(ele);
              });
            }
          });
        }
        v.$forceUpdate();
      }).catch(() => v.isGet = false);
    },
    // 取消收藏
    unsubscribeBtn(type, data, index) {
      let v = this;
      let ymsProductIds = [];
      // 单个收藏
      if (type === 'single') {
        ymsProductIds = [data.ymsProductId];
      } else {
        v.shopList.map((item) => {
          if (item.checked) {
            ymsProductIds.push(item.ymsProductId);
          }
        });
        if (ymsProductIds.length <= 0) {
          v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1004409'));
          return false;
        }
      }
      getDistributorErpConfig(true).then((res) => {
        let distributorMerchantId = res.merchant.merchantId;
        if (distributorMerchantId) {
          v.axios.post(api.post_ymsDistributorMerchantSubscription_cancelSubscription,
            ymsProductIds).then(response => {
            if (response.data.code === 0) {
              v.$Message.success(alias1bddc4d174174d47a6dfb0437dace856.t('key1004410'));
              v.$set(v.shopList[index], 'subscribed', false);
              v.$forceUpdate();
              if (v.fromListing && document.referrer) {
                window.parent.postMessage({
                  type: 'subscriptionProduct',
                  ymsProductId: data.ymsProductId,
                  from: 'list',
                  status: 0
                }, '*');
              }
              // v.pageParamsStatus = true;
            }
          });
        }
      });
    },
    // 收藏商品
    subscribeBtn(type, data, index) {
      let v = this;
      let ymsProductIds = [];
      // 单个收藏
      if (type === 'single') {
        ymsProductIds = [data.ymsProductId];
      } else {
        v.shopList.map((item) => {
          if (item.checked) {
            ymsProductIds.push(item.ymsProductId);
          }
        });
        if (ymsProductIds.length <= 0) {
          v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1004411'));
          return false;
        }
      }
      getDistributorErpConfig(true).then((res) => {
        let distributorMerchantId = res.merchant.merchantId;
        if (distributorMerchantId) {
          v.axios.post(api.post_ymsDistributorMerchantSubscription_subscription,
            ymsProductIds).then(response => {
            if (response.data.code === 0) {
              v.$Message.success(alias1bddc4d174174d47a6dfb0437dace856.t('key1004412'));
              v.$set(v.shopList[index], 'subscribed', true);
              v.$forceUpdate();
              if (v.fromListing && document.referrer) {
                window.parent.postMessage({
                  type: 'subscriptionProduct',
                  ymsProductId: data.ymsProductId,
                  from: 'list',
                  status: 1
                }, '*');
              }
              // v.pageParamsStatus = true;
            }
          });
        }
      });
    },
    // 下载资料
    downProductData(type, data) {
      let v = this;
      let ymsProductIds = [];
      if (type === 'single') {
        ymsProductIds = [data];
      } else {
        v.shopList.map((item) => {
          if (item.checked) {
            ymsProductIds.push(item.ymsProductId);
          }
        });
        if (ymsProductIds.length <= 0) {
          v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1004413'));
          return false;
        }
      }
      getDistributorErpConfig(true).then((res) => {
        let distributorMerchantId = res.merchant.merchantId;

        if (distributorMerchantId) {
          let query = {
            commodityPictures: ["carousel", "attribute", "sizeTable"],
            isCommodityInformation: 1,
            ymsProductIds: ymsProductIds
          }
          v.axios.post(api.post_ymsDistributorProductInfo_download, query, {
            loading: true,
            loadingText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000212')
          }).then(response => {
            if (response.data.code === 0) {
              let taskNumber = response.data.datas;
              setTimeout(() => {
                pageJump('/distributor.html#/exportTask?operateCode=' + taskNumber, '/yms-distributor-service', '_blank');
              }, 30)
            }
          });
        }
      });
    },
    // 一键刊登
    publishBtn(data) {
      let source = data.source;
      let platformJson = data.platformJson;
      getDistributorErpConfig(true).then((res) => {
        let distributorMerchantId = res.merchant.merchantId;
        if (distributorMerchantId) {
          this.ymsProductId = data.ymsProductId;
          if (source === 'listing') {
            if (platformJson) {
              let obj = JSON.parse(platformJson);
              let platformIds = obj.platformIds || [];
              this.$refs['publishingPlatform'].initPublishingPlatformData(platformIds, data.ymsProductCategoryId, source);
            }
          } else {
            this.$refs['publishingPlatform'].initPublishingPlatformData(data.availablePlatforms, data.ymsProductCategoryId);
          }
        }
      });
    },
    // 选择刊登平台之后
    changePlatform(data) {
      let listingStatus = this.$store.state.erpConfig.merchant.listingStatus;
      let type = Number(listingStatus)
      this.$refs['selectPublishStore'].initSelectPublishStoreData(data, this.ymsProductId, type);
    },
    // 点击轮播图图片进入商品详情页
    changeCarousel(index, item) {
      this.toShopDetails(item);
    },
    // 排序操作按钮
    sortBtn(ele, idx) {
      let v = this;
      let talg = v.currentIndex === idx;
      v.pageParams.upDown = talg ? v.pageParams.upDown : 'up';
      v.sortList.map((item, index) => {
        item.selected = false;
        item.type = null;
        if (index === idx) {
          let type = null;
          item.selected = true;
          v.currentIndex = idx;
          if (item.sort) {
            if (v.pageParams.upDown == 'down') {
              type = 'up';
            } else {
              type = 'down';
            }
            item.type = type;
          }
          v.pageParams.orderBy = item.paramsKey;
          v.pageParams.upDown = item.type;
        }
      });
      v.pageParamsStatus = true;
    },
    // 升序、降序按钮
    sortupDownBtn(idx, type) {
      let v = this;
      v.sortList.map((item, index) => {
        item.selected = false;
        item.type = null;
        if (index === idx) {
          item.selected = true;
          item.type = type;
          v.pageParams.orderBy = item.paramsKey;
          v.pageParams.upDown = type;
        }
      });
      v.pageParamsStatus = true;
      v.$forceUpdate();
    },
    // 进入列表详情页
    toShopDetails(data) {
      let v = this;
      let hostname = window.location.hostname;
      let arr = hostname.split('.yms.com');
      let _fromListing = '';
      if (v.fromListing) {
        _fromListing = '?from=listing';
      }
      let _url = document.referrer;
      if (v.fromListing && _url) {
        let _params = 'distrimarket/index.htm?ymsProductId=' + data.ymsProductId;
        if (_url.includes('distrimarket/index.htm')) {
          window.open(_url + '?ymsProductId=' + data.ymsProductId);
        } else {
          window.open(_url + _params);
        }
        return;
      }
      if (arr[0].includes('shop')) {
        window.open(v.$store.state.shoppingInfo.shoppingDomain + '/index.html#/shopDetails/' + data.ymsProductId + _fromListing, '_blank');
      } else {
        const {href} = v.$router.resolve({
          path: `/shopDetails/${data.ymsProductId}${_fromListing}`
        });
        window.open(href, '_blank');
      }
    },
    // 处理侧边栏分类的固定位置
    handleFixedStyles() {
      let v = this;
      if (v.fromListing) return;
      let top = 0;
      let left = '357px';
      let hostname = window.location.hostname;
      let arr = hostname.split('.yms.com');
      if (arr[0].includes('shop')) {
        top = '95px';
      } else {
        top = '175px';
      }
      let talgWidth = 1200;
      if (v.screenWidth > 1200) {
        let distanceWidth = parseInt((v.screenWidth - talgWidth) / 2);
        left = distanceWidth + 'px';
      } else {
        left = '0px';
      }
      return {
        top: top,
        left: left
      }
    },
    // 勾选中所属仓库
    changeYmsWarehouse(data) {
      let v = this;
      if (data.length > 0) {
        if (data.includes('null')) {
          v.pageParams.ymsWarehouseIdList = v.warehouselist.map((item) => {
            return item.ymsWarehouseId
          });
        } else {
          v.pageParams.ymsWarehouseIdList = data;
        }
      } else {
        v.pageParams.ymsWarehouseIdList = [];
      }
      v.getList();
    }
  },
  watch: {
    // 监听参数变化更新数据
    pageParamsStatus(value) {
      let v = this;
      if (value) {
        v.getList();
        v.pageParamsStatus = false;
      }
    },
    // 监听参数变化
    $route: {
      handler(data) {
        let v = this;
        v.Initialization(data.query);
      },
      deep: true,
      immediate: true
    },
    // 监听页面窗口大小
    screenWidth(val) {
      let v = this;
      if (!v.timer) {
        v.screenWidth = val;
        v.timer = true
        setTimeout(function () {
          v.handleFixedStyles()
          v.timer = false
        }, 200)
      }
    }
  },
  components: {
    publishingPlatformModal,
    selectPublishStoreModal,
    imageBox,
    vueTreeSelect
  }
};
</script>

<style lang="less" scoped>
@import "src/assets/styles/shopping/common";

.down_icon {
  font-size: 16px;
  color: #BEBEBE;
}

.list_banner_style {
  margin: 0 auto 15px;
  max-width: 1200px;
  text-align: center;

  img {
    width: 100%;
  }
}

.publicList_box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .classification_box {
    width: 240px;
    min-height: 200px;
    max-height: 680px;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 0 16px;
    margin-bottom: 15px;
    overflow-y: auto;

    .classify_box {
      .tree_box {
        /deep/ .ivu-tree-arrow {
          display: none !important;
        }

        /deep/ .title_styles {
          max-width: 180px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: break-word;
          word-break: break-all;
          font-family: 'PingFangSC-Light';
          opacity: 0.8;
          font-size: 14px;
          color: #000;
        }

        /deep/ .catalogue_title {
          color: #000;
          font-size: 14px;
          font-weight: bold;
          font-family: 'PingFangSC-Medium';
        }

        /deep/ .ivu-tree-title-selected, /deep/ .ivu-tree-title-selected:hover {
          width: 100%;
          background-color: #fff !important;
          font-weight: bold;
        }

        /deep/ .ivu-tree-title:hover {
          background-color: #fff !important;
        }

        /deep/ .ivu-tree-title-selected {
          .title_styles {
            color: #FD5425 !important;
          }
        }
      }

      .classify_box_item {
        width: 100%;

        .title {
          width: 100%;
          color: #000;
          font-size: 15px;
          font-weight: bold;
          font-family: 'PingFangSC-Medium';

          &:hover {
            color: @subject_color;
          }
        }

        .classify_box_children {
          padding-left: 16px;

          .classify_children_title {
            width: 100%;
            font-family: 'PingFangSC-Light';
            opacity: 0.8;
            font-size: 14px;
            color: #000;

            &:hover {
              color: @subject_color;
            }
          }
        }

        h2 {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
          height: 27px;
        }
      }
    }

    .picture_type_box {
      margin: 35px 0;

      .title {
        font-family: 'PingFangSC-Medium';
        font-size: 15px;
        font-weight: 600;
        color: #000;
      }

      .img_type_style {
        margin-top: 10px;
        color: #000;
        font-size: 14px;
        font-family: 'PingFangSC-Regular';
      }
    }
  }

  .fixed_box_style {
    width: 240px;
    min-height: 200px;
    background: #FFFFFF;
    border-radius: 4px;
    visibility: hidden;
  }

  .fixed_classification_box {
    position: fixed;
    z-index: 10;
    top: 175px;
  }

  .shop_list_box {
    width: 960px;

    .breadcrumb_box {
      width: 100%;
      height: 40px;
      padding-left: 16px;
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      .breadcrumb_title {
        font-size: 14px;
        font-family: 'PingFangSC-Regular';
        color: #000;
        opacity: 0.5;
      }

      /deep/ .ivu-breadcrumb-item-separator {
        color: #515a6e;
        margin: 0 4px 0 0;
      }
    }

    .filter_box {
      padding-left: 16px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .filter_box_item {
        display: flex;
        align-items: center;
        margin: 0 30px 16px 0;

        .sort_content {
          min-width: 300px;
          height: 32px;
          background: #FFFFFF;
          border: 1px solid #D7D7D7;
          border-radius: 4px;
          display: flex;
          align-items: center;

          .sort_content_item {
            display: flex;
            align-items: center;

            .sort_title {
              font-family: 'PingFangSC-Regular';
              font-size: 14px;
              color: #000;
              opacity: 0.8;
              cursor: pointer;
              padding: 0 12px;
            }

            .sort_active {
              font-weight: bold;
              color: @subject_color;
            }

            .sort_icon_box {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              margin-right: 4px;

              .dropup_icon {
                position: relative;
                top: 4px;
              }

              .dropdown_icon {
                position: relative;
                bottom: 4px;
              }
            }

            .line_txt {
              display: inline-block;
              width: 1px;
              height: 14px;
              background-color: #dedcdc;
            }
          }

          .sort_content_item:last-child {
            .line_txt {
              display: none;
            }
          }
        }

        .line_item {
          display: inline-block;
          margin: 0 4px;
          font-width: bold;
        }

        .price_input {
          width: 150px;
          height: 32px;
          border-radius: 4px;
          font-size: 14px;
        }

        .stock_select {
          width: 110px;

          /deep/ .ivu-select-selection {
            height: 30px;
            border: none !important;
            box-shadow: initial !important;
            border-radius: initial !important;
            border-right: 1px solid #dcdee2 !important;
          }
        }

        .stock_input_box {
          display: flex;
          align-items: center;

          .stock_input {
            width: 150px;
            height: 30px;
            border: none !important;
            box-shadow: initial !important;
            border-radius: initial !important;

            /deep/ .ivu-input-number-handler-wrap {
              display: none;
            }

            /deep/ .ivu-input-number-input {
              text-align: center;
            }
          }
        }

        .distributor_reset_vueTreeselect_style {
          vertical-align: middle;
          display: inline-block;

          /deep/ .vue-treeselect__control {
            line-height: initial !important;

            .vue-treeselect__multi-value {
              margin-bottom: 0;
            }

            .vue-treeselect__label {
              font-size: 13px;
            }

            .vue-treeselect__multi-value-item {
              background: #FFF6F0;
              color: #FD5425;
            }

            .vue-treeselect__value-remove {
              color: #FD5425;
            }

            .vue-treeselect__multi-value-item-container {
              padding-top: 2px;
            }
          }

          /deep/ .vue-treeselect__menu-container {
            .vue-treeselect__checkbox--checked {
              border-color: #FD5425;
              background: #FD5425;
            }

            .vue-treeselect__checkbox--indeterminate {
              border-color: #FD5425 !important;
              background: #FD5425 !important;
            }
          }
        }
      }

      .stock_styles {
        border-radius: 4px;
        border: 1px solid #dcdee2;
        background-color: #fff;
        box-sizing: border-box;
        overflow: hidden;
      }
    }

    .shop_content_box {
      display: flex;
      flex-wrap: wrap;

      .shop_content_item {
        width: 224px;
        height: 294px;
        border-radius: 4px;
        margin: 0 0 16px 16px;
        background-color: #fff;
        position: relative;

        .img_style_box {
          width: 224px;
          height: 224px;
          overflow: hidden;
          position: relative;
        }

        /deep/ .ivu-carousel {
          width: 100%;
          height: 100%;
          cursor: pointer;

          .ivu-carousel-arrow {
            width: 24px;
            height: 32px;
            background: #000000;
            opacity: 0.4;
            display: none;
            justify-content: center;
            align-items: center;

            .ivu-icon {
              font-size: 22px;
              color: #fff;
            }
          }

          .ivu-carousel-arrow.right {
            right: 0;
            border-radius: 4px 0px 0px 4px;
          }

          .ivu-carousel-arrow.left {
            border-radius: 0px 4px 4px 0px;
            left: 0;
          }

          &:hover {
            .ivu-carousel-arrow {
              display: flex;
            }
          }
        }

        .options_box {
          display: flex;
          align-items: center;
          width: 100%;
          position: absolute;
          left: 0;
          bottom: -32px;
          z-index: 200;
          background-color: rgba(255, 255, 255, 0.8);
          transition: all .3s ease-in;

          .opts {
            display: inline-block;
            width: 112px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            font-size: 14px;
            color: #000;
            font-family: 'PingFangSC-Regular';
            cursor: pointer;

            &:hover {
              color: #fff;
              background-color: @subject_color;
            }
          }

          .line_item {
            display: inline-block;
            width: 1px;
            height: 32px;
            background: #d9d9d9;
          }
        }

        .list_title {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #000;
          font-size: 14px;
          font-family: 'PingFangSC-Regular';
          padding: 0 8px;
          margin: 6px 0 8px 0;
          cursor: pointer;

          &:hover {
            color: @subject_color;
            text-decoration: underline;
          }
        }

        .money_box {
          .price {
            font-family: 'PingFangSC-Semibold';
            padding: 0 8px;
            font-size: 14px;
            font-weight: 600;
            color: #000;
            cursor: pointer;
          }

          .original_price_style {
            color: #8D8D8D;
            text-decoration: line-through;
            padding-left: 5px;
            font-weight: normal;
          }
        }

        .subscribe_box, .unsubscribed_box {
          position: absolute;
          background-color: #fff;
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          top: 8px;
          right: 8px;
          z-index: 100;
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2000);
          cursor: pointer;
        }

        .unsubscribed_box {
          display: none;
        }

        .activity_label_box {
          position: absolute;
          top: 0px;
          left: 0;
          display: flex;
          flex-direction: column;
          z-index: 100;

          .activity_label_item {
            color: #fff;
            font-size: 12px;
            border-bottom-right-radius: 15px;
            padding: 2px 8px;
            display: inline-block;
            margin-bottom: 8px;
          }
        }

        &:hover {
          box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1500);

          .options_box {
            bottom: 0;
          }

          .unsubscribed_box {
            display: flex;
          }
        }
      }
    }
  }
}

.preview_image_search {
  width: 1200px;
  margin: 16px auto;

  img {
    display: inline-block;
    width: auto;
    height: auto;
    max-width: 160px;
    max-height: 160px;
    border-radius: 5px;
    overflow: hidden;
  }
}

</style>
